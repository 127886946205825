/* Inputs */
%field-style {
  padding: 17px;
  color: $color--gray;
  width: 100%;
  display: block;
  font-size: 16px;
  line-height: 30px;
  font-weight: normal;
  border-radius: $border-radius;
  background-color: $color--gray3;
  border: 1px solid transparent;
  height: 50px;
  transition: .2s $cubic;

  &:disabled {
    pointer-events: none;
    background-color: $color--gray;
  }

  &:focus {
    outline: 0;
    border-color: $color--red;
    filter: $drop-shadow;
  }

  &::placeholder {
    color: $color--gray;
  }

  &:-ms-input-placeholder {
    color: $color--gray;
  }

  &::-ms-input-placeholder {
    color: $color--gray;
  }

  @include min-screen(bp("page")) {
    height: 70px;
    padding: 25px 30px;
  }
}

.form-label {
  display: block;
  margin-bottom: 5px;
  font-weight: normal;

  @include min-screen(bp("page")) {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 10px;
  }
}

.form-row {
  @include min-screen(bp("phablet")) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-start;
    column-gap: 15px;
  }

  @include min-screen(bp("page")) {
    column-gap: 30px;
  }
}

.form-row--5 {
  @include min-screen(bp("phablet")) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: flex-start;
    gap: $unit-spacing;
  }

  @include min-screen(bp("page")) {
    grid-template-columns: repeat(5, 1fr);
  }
}

.form-group {
  width: 100%;
  margin-bottom: 20px;

  @include min-screen(bp("page")) {
    margin-bottom: 30px;
  }
}

.form-input {
  @extend %field-style;

  &-icon {
    position: relative;

    .form-input {
      padding-left: 45px;
      margin-bottom: $unit-spacing / 2;

      + i {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  @include min-screen(bp("page")) {
    &--small {
      height: 58px;
      padding: 20px 10px;
    }

    &-icon {
      .form-input {
        margin-bottom: 0;
      }
    }
  }
}

.form-select {
  @extend %field-style;
  color: $color--gray;
  -webkit-appearance: none;
}

.form-textarea {
  @extend %field-style;
  height: 130px;
  resize: none;

  @include min-screen(bp("page")) {
    height: 130px;
  }
}

.form-checkbox {
  display: block;
  text-align: left;
  margin-right: $unit-spacing * 2;
  margin-bottom: 20px;

  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
    cursor: pointer;
  }

  input[type="text"] {
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid $color--gray2;
    font-size: 14px;
    padding: 5px 0;
    margin-left: 3px;
    width: 200px;

    &:focus {
      outline: 0;
    }
  }

  &--with-input {
    display: flex;
  }

  &__label {
    cursor: pointer;
    color: $color--text;
    font-size: 14px;
    line-height: 16px;
    font-weight: normal;
    display: flex;
    justify-content: space-between;
    padding-left: 45px;
    position: relative;
    height: 35px;
    align-items: center;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 35px;
      height: 35px;
      background-color: transparent;
      border-radius: $border-radius;
      border: 2px solid $color--gray2;
      transition: .2s $cubic;
    }

    &:after {
      @include fontIcon();
      content: "\e906";
      position: absolute;
      font-size: 14px;
      left: 10px;
      top: 10px;
      opacity: 0;
      color: $color--red;
      transform: scale(.3);
      transition: .2s $cubic;
    }
  }

  input[type="checkbox"]:checked ~ .form-checkbox__label::after {
    opacity: 1;
    transform: scale(1);
  }

  @include min-screen(bp("large")) {
    &__label {
      font-size: 16px;
      line-height: 20px;
    }

    input[type="text"] {
      width: 329px;
    }
  }
}
