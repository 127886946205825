/* LP */
.lp-banner {
  &__image {
    margin-top: $spacer / 2;
    width: 100%;
    border-radius: $border-radius;
    overflow: hidden;

    @include min-screen(bp("large")) {
      margin-top: $spacer;
    }
  }
}

.lp-inside {
  &__box {
    padding: $unit-spacing * 2;
    border: 1px solid $color--gray;
    border-radius: $border-radius;
    
    @include min-screen(bp("page")) {
      padding: 80px 70px;
    }
  }
}

.lp-benefits {
  &__grid {
    display: flex;
    flex-wrap: wrap;
  }

  .lp-benefit {
    padding: 42px 30px 51px;
    border-radius: $border-radius;
    background-color: $color--gray4;
    color: $color--gray;
    font-size: 16px;
    line-height: 26px;
    width: 100%;
    margin-bottom: $unit-spacing;

    &__title {
      color: $color--white;
      font-size: 24px;
      line-height: 29px;
      font-weight: bold;
      margin-bottom: 21px;
      display: inline-flex;
      align-items: center;

      &::before {
        @include fontIcon();
        content: "\e907";
        font-size: 20px;
        margin-right: 10px;
        color: $color--red2;
      }
    }

    &--button {
      background-color: $color--red;
      color: $color--white;
      font-size: 20px;
      font-weight: bold;
      line-height: 24px;
      padding: $unit-spacing * 2;
      text-transform: uppercase;
      filter: $drop-shadow;
      display: grid;
      grid-template-columns: 1fr 26px;
      align-items: center;
      transition: .2s $cubic;

      i {
        font-size: 26px;
      }

      &:hover {
        background-color: $color--red2;
      }
    }

    @include min-screen(bp("large")) {
      width: 40.2%;

      &:nth-child(odd) {
        margin-right: $unit-spacing;
      }

      &--big {
        width: 57.2%;
      }

      &--half {
        width: 48.7%;
      }

      &--button {
        padding: 50px;
      }
    }

    @include min-screen(bp("page")) {
      margin-bottom: $unit-spacing * 2;

      &:nth-child(odd) {
        margin-right: $unit-spacing * 2;
      }
    }
  }
}

.lp-how-to-win {
  .section-title {
    margin-bottom: $unit-spacing / 2;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: $unit-spacing;
    margin-top: $unit-spacing * 2;
  }

  &__block {
    padding: $unit-spacing * 2;
    border-radius: $border-radius;
    background-color: $color--gray4;
    color: $color--gray;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: $unit-spacing * 2;
    position: relative;

    &-step {
      position: absolute;
      top: -17px;
      left: 35px;
      filter: $drop-shadow;
      width: 35px;
      height: 35px;
      border-radius: 100%;
      font-size: 20px;
      font-weight: bold;
      background-color: $color--red;
      color: $color--white;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }

  @include min-screen(bp("mobile")) {
    &__grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @include min-screen(bp("large")) {
    &__grid {
      grid-template-columns: repeat(4, 1fr);
      gap: $unit-spacing * 2;
      margin-top: $spacer;
    }

    &__block {
      padding: 48px 38px;

    }
  }
}

.lp-join {
  &__form {
    padding: $unit-spacing * 2;
    border-radius: $border-radius;
    background-color: $color--gray4;
  }

  .button {
    text-align: center;
  }


  @include min-screen(bp("page")) {
    &__form {
      padding: 110px 70px;
    }

    .form-checkbox:nth-child(7),
    .form-checkbox:nth-child(12) {
      margin-right: 0;
    }
  }
}

.lp-thankyou {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  &__inner {
    width: 100%;
    max-width: 600px;
    text-align: center;
    padding: $unit-spacing;
  }

  &__message {
    background-color: $color--red;
    filter: $drop-shadow;
    padding: $spacer;
    border-radius: $border-radius;
    margin-bottom: $unit-spacing * 2;
  }
}
