/* Buttons */
.button {
  @include btnReset();
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 16px 27px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  height: 50px;
  border-radius: $border-radius;
  transition: .2s $cubic;

  @include max-screen(bp("large") - 1px) {
    padding: $unit-spacing;
    height: 40px;
    font-size: 14px;
    line-height: 17px;
  }
}

.button--primary {
  color: $color--white;
  background-color: $color--red2;

  &:hover {
    background-color: darken($color--red2, 20%);
  }
}

.button--big {
  padding: 28px 40px;
  height: 80px;
  background-color: $color--red;
  filter: $drop-shadow;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 26px;
  align-items: center;
  text-align: left;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;

  i {
    font-size: 26px;
  }

  &:hover {
    background-color: $color--red2;
  }

  @include max-screen(bp("large") - 1px) {
    font-size: 18px;
    line-height: 22px;
    padding: $unit-spacing $unit-spacing * 2;
    height: 80px;
  }
}
