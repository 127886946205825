/* Variables */
/* Font */
/* Typo */
/* Colors */
/* Misc */
/* Mixins */
/* Fonts */
@font-face {
  font-family: "font-icon";
  src: url("../fonts/font-icon.eot");
  src: url("../fonts/font-icon.eot#iefix") format("embedded-opentype"), url("../fonts/font-icon.ttf") format("truetype"), url("../fonts/font-icon.woff") format("woff"), url("../fonts/font-icon.svg#font-icon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

/* Placeholders */
/* Footer */
/* line 2, src/scss/layout/_footer.scss */
.footer {
  background-color: #272d33;
  margin-top: 60px;
  padding: 60px 0 45px;
  position: relative;
}

/* line 8, src/scss/layout/_footer.scss */
.footer__copy {
  width: 100%;
  font-size: 12px;
  line-height: 22px;
  color: #768ea8;
  order: 2;
  text-align: center;
}

/* line 16, src/scss/layout/_footer.scss */
.footer__copy p + p {
  margin-top: 5px;
}

/* line 21, src/scss/layout/_footer.scss */
.footer__logo {
  margin-bottom: 20px;
}

/* line 25, src/scss/layout/_footer.scss */
.footer__social {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

/* line 33, src/scss/layout/_footer.scss */
.footer .partner {
  font-size: 12px;
  line-height: 22px;
  text-transform: uppercase;
  margin: 30px 0 10px;
  color: #768ea8;
}

@media only screen and (min-width: 1024px) {
  /* line 2, src/scss/layout/_footer.scss */
  .footer {
    padding: 106px 0;
    margin-top: 145px;
  }
  /* line 45, src/scss/layout/_footer.scss */
  .footer__copy {
    order: -1;
    width: 74.4%;
    text-align: left;
  }
  /* line 51, src/scss/layout/_footer.scss */
  .footer__social {
    width: 20%;
    margin-bottom: 0;
  }
}

/* line 58, src/scss/layout/_footer.scss */
.scroll-top {
  position: absolute;
  left: 50%;
  top: -25px;
  margin-left: -25px;
  width: 50px;
  height: 50px;
  background-color: #d51f26;
  color: #fff;
  font-size: 18px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* line 73, src/scss/layout/_footer.scss */
.scroll-top i {
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

/* line 77, src/scss/layout/_footer.scss */
.scroll-top:hover i {
  margin-top: -5px;
}

/* line 82, src/scss/layout/_footer.scss */
.social {
  display: flex;
  justify-content: center;
}

/* line 86, src/scss/layout/_footer.scss */
.social li {
  margin: 0 5px;
}

/* line 90, src/scss/layout/_footer.scss */
.social .icon-social {
  width: 34px;
  height: 34px;
  font-size: 16px;
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

/* line 96, src/scss/layout/_footer.scss */
.social .icon-social:hover {
  background-color: #d51f26;
  color: #fff;
}

/* Inputs */
/* line 2, src/scss/components/_inputs.scss */
.form-input, .form-select, .form-textarea {
  padding: 17px;
  color: #768ea8;
  width: 100%;
  display: block;
  font-size: 16px;
  line-height: 30px;
  font-weight: normal;
  border-radius: 10px;
  background-color: #2d3640;
  border: 1px solid transparent;
  height: 50px;
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

/* line 16, src/scss/components/_inputs.scss */
.form-input:disabled, .form-select:disabled, .form-textarea:disabled {
  pointer-events: none;
  background-color: #768ea8;
}

/* line 21, src/scss/components/_inputs.scss */
.form-input:focus, .form-select:focus, .form-textarea:focus {
  outline: 0;
  border-color: #d51f26;
  filter: drop-shadow(3px 3px 20px rgba(213, 31, 38, 0.8));
}

/* line 27, src/scss/components/_inputs.scss */
.form-input::placeholder, .form-select::placeholder, .form-textarea::placeholder {
  color: #768ea8;
}

/* line 31, src/scss/components/_inputs.scss */
.form-input:-ms-input-placeholder, .form-select:-ms-input-placeholder, .form-textarea:-ms-input-placeholder {
  color: #768ea8;
}

/* line 35, src/scss/components/_inputs.scss */
.form-input::-ms-input-placeholder, .form-select::-ms-input-placeholder, .form-textarea::-ms-input-placeholder {
  color: #768ea8;
}

@media only screen and (min-width: 1200px) {
  /* line 2, src/scss/components/_inputs.scss */
  .form-input, .form-select, .form-textarea {
    height: 70px;
    padding: 25px 30px;
  }
}

/* line 45, src/scss/components/_inputs.scss */
.form-label {
  display: block;
  margin-bottom: 5px;
  font-weight: normal;
}

@media only screen and (min-width: 1200px) {
  /* line 45, src/scss/components/_inputs.scss */
  .form-label {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 767px) {
  /* line 57, src/scss/components/_inputs.scss */
  .form-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-start;
    column-gap: 15px;
  }
}

@media only screen and (min-width: 1200px) {
  /* line 57, src/scss/components/_inputs.scss */
  .form-row {
    column-gap: 30px;
  }
}

@media only screen and (min-width: 767px) {
  /* line 70, src/scss/components/_inputs.scss */
  .form-row--5 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: flex-start;
    gap: 15px;
  }
}

@media only screen and (min-width: 1200px) {
  /* line 70, src/scss/components/_inputs.scss */
  .form-row--5 {
    grid-template-columns: repeat(5, 1fr);
  }
}

/* line 83, src/scss/components/_inputs.scss */
.form-group {
  width: 100%;
  margin-bottom: 20px;
}

@media only screen and (min-width: 1200px) {
  /* line 83, src/scss/components/_inputs.scss */
  .form-group {
    margin-bottom: 30px;
  }
}

/* line 95, src/scss/components/_inputs.scss */
.form-input-icon {
  position: relative;
}

/* line 98, src/scss/components/_inputs.scss */
.form-input-icon .form-input {
  padding-left: 45px;
  margin-bottom: 7.5px;
}

/* line 102, src/scss/components/_inputs.scss */
.form-input-icon .form-input + i {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (min-width: 1200px) {
  /* line 112, src/scss/components/_inputs.scss */
  .form-input--small {
    height: 58px;
    padding: 20px 10px;
  }
  /* line 118, src/scss/components/_inputs.scss */
  .form-input-icon .form-input {
    margin-bottom: 0;
  }
}

/* line 125, src/scss/components/_inputs.scss */
.form-select {
  color: #768ea8;
  -webkit-appearance: none;
}

/* line 131, src/scss/components/_inputs.scss */
.form-textarea {
  height: 130px;
  resize: none;
}

@media only screen and (min-width: 1200px) {
  /* line 131, src/scss/components/_inputs.scss */
  .form-textarea {
    height: 130px;
  }
}

/* line 141, src/scss/components/_inputs.scss */
.form-checkbox {
  display: block;
  text-align: left;
  margin-right: 30px;
  margin-bottom: 20px;
}

/* line 147, src/scss/components/_inputs.scss */
.form-checkbox input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
  cursor: pointer;
}

/* line 155, src/scss/components/_inputs.scss */
.form-checkbox input[type="text"] {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #36414d;
  font-size: 14px;
  padding: 5px 0;
  margin-left: 3px;
  width: 200px;
}

/* line 164, src/scss/components/_inputs.scss */
.form-checkbox input[type="text"]:focus {
  outline: 0;
}

/* line 169, src/scss/components/_inputs.scss */
.form-checkbox--with-input {
  display: flex;
}

/* line 173, src/scss/components/_inputs.scss */
.form-checkbox__label {
  cursor: pointer;
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  font-weight: normal;
  display: flex;
  justify-content: space-between;
  padding-left: 45px;
  position: relative;
  height: 35px;
  align-items: center;
}

/* line 186, src/scss/components/_inputs.scss */
.form-checkbox__label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 35px;
  height: 35px;
  background-color: transparent;
  border-radius: 10px;
  border: 2px solid #36414d;
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

/* line 199, src/scss/components/_inputs.scss */
.form-checkbox__label:after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "font-icon";
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: top;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e906";
  position: absolute;
  font-size: 14px;
  left: 10px;
  top: 10px;
  opacity: 0;
  color: #d51f26;
  transform: scale(0.3);
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

/* line 213, src/scss/components/_inputs.scss */
.form-checkbox input[type="checkbox"]:checked ~ .form-checkbox__label::after {
  opacity: 1;
  transform: scale(1);
}

@media only screen and (min-width: 1024px) {
  /* line 219, src/scss/components/_inputs.scss */
  .form-checkbox__label {
    font-size: 16px;
    line-height: 20px;
  }
  /* line 224, src/scss/components/_inputs.scss */
  .form-checkbox input[type="text"] {
    width: 329px;
  }
}

/* Buttons */
/* line 2, src/scss/components/_buttons.scss */
.button {
  background-color: transparent;
  border: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 16px 27px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  height: 50px;
  border-radius: 10px;
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

/* line 49, src/scss/base/_mixins.scss */
.button:focus, .button:active {
  outline: 0;
}

@media only screen and (max-width: 1023px) {
  /* line 2, src/scss/components/_buttons.scss */
  .button {
    padding: 15px;
    height: 40px;
    font-size: 14px;
    line-height: 17px;
  }
}

/* line 25, src/scss/components/_buttons.scss */
.button--primary {
  color: #fff;
  background-color: #ff252d;
}

/* line 29, src/scss/components/_buttons.scss */
.button--primary:hover {
  background-color: #be0007;
}

/* line 34, src/scss/components/_buttons.scss */
.button--big {
  padding: 28px 40px;
  height: 80px;
  background-color: #d51f26;
  filter: drop-shadow(3px 3px 20px rgba(213, 31, 38, 0.8));
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 26px;
  align-items: center;
  text-align: left;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
}

/* line 48, src/scss/components/_buttons.scss */
.button--big i {
  font-size: 26px;
}

/* line 52, src/scss/components/_buttons.scss */
.button--big:hover {
  background-color: #ff252d;
}

@media only screen and (max-width: 1023px) {
  /* line 34, src/scss/components/_buttons.scss */
  .button--big {
    font-size: 18px;
    line-height: 22px;
    padding: 15px 30px;
    height: 80px;
  }
}

/* Message Feedback */
/* line 2, src/scss/components/_message-feedback.scss */
.message-feedback {
  position: fixed;
  bottom: 15px;
  right: -100%;
  z-index: 10000;
  background-color: #353535;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: #fff;
  visibility: hidden;
  opacity: 0;
  will-change: opacity, visibility, right;
  transition: 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

/* line 18, src/scss/components/_message-feedback.scss */
.message-feedback.success {
  background-color: #2ee66b;
}

/* line 22, src/scss/components/_message-feedback.scss */
.message-feedback.error {
  background-color: #d51f26;
}

/* line 26, src/scss/components/_message-feedback.scss */
.message-feedback__content {
  width: 100%;
  max-width: 300px;
  position: relative;
  font-size: 18px;
  line-height: 24px;
  padding: 15px 45px 15px 15px;
  font-weight: normal;
}

/* line 36, src/scss/components/_message-feedback.scss */
.message-feedback__close {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 10;
}

/* line 45, src/scss/components/_message-feedback.scss */
.message-feedback.is-active {
  opacity: 1;
  right: 15px;
  visibility: visible;
}

/* LP */
/* line 3, src/scss/pages/_lp.scss */
.lp-banner__image {
  margin-top: 30px;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}

@media only screen and (min-width: 1024px) {
  /* line 3, src/scss/pages/_lp.scss */
  .lp-banner__image {
    margin-top: 60px;
  }
}

/* line 16, src/scss/pages/_lp.scss */
.lp-inside__box {
  padding: 30px;
  border: 1px solid #768ea8;
  border-radius: 10px;
}

@media only screen and (min-width: 1200px) {
  /* line 16, src/scss/pages/_lp.scss */
  .lp-inside__box {
    padding: 80px 70px;
  }
}

/* line 28, src/scss/pages/_lp.scss */
.lp-benefits__grid {
  display: flex;
  flex-wrap: wrap;
}

/* line 33, src/scss/pages/_lp.scss */
.lp-benefits .lp-benefit {
  padding: 42px 30px 51px;
  border-radius: 10px;
  background-color: #272d33;
  color: #768ea8;
  font-size: 16px;
  line-height: 26px;
  width: 100%;
  margin-bottom: 15px;
}

/* line 43, src/scss/pages/_lp.scss */
.lp-benefits .lp-benefit__title {
  color: #fff;
  font-size: 24px;
  line-height: 29px;
  font-weight: bold;
  margin-bottom: 21px;
  display: inline-flex;
  align-items: center;
}

/* line 52, src/scss/pages/_lp.scss */
.lp-benefits .lp-benefit__title::before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "font-icon";
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: top;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e907";
  font-size: 20px;
  margin-right: 10px;
  color: #ff252d;
}

/* line 61, src/scss/pages/_lp.scss */
.lp-benefits .lp-benefit--button {
  background-color: #d51f26;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  padding: 30px;
  text-transform: uppercase;
  filter: drop-shadow(3px 3px 20px rgba(213, 31, 38, 0.8));
  display: grid;
  grid-template-columns: 1fr 26px;
  align-items: center;
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

/* line 75, src/scss/pages/_lp.scss */
.lp-benefits .lp-benefit--button i {
  font-size: 26px;
}

/* line 79, src/scss/pages/_lp.scss */
.lp-benefits .lp-benefit--button:hover {
  background-color: #ff252d;
}

@media only screen and (min-width: 1024px) {
  /* line 33, src/scss/pages/_lp.scss */
  .lp-benefits .lp-benefit {
    width: 40.2%;
  }
  /* line 87, src/scss/pages/_lp.scss */
  .lp-benefits .lp-benefit:nth-child(odd) {
    margin-right: 15px;
  }
  /* line 91, src/scss/pages/_lp.scss */
  .lp-benefits .lp-benefit--big {
    width: 57.2%;
  }
  /* line 95, src/scss/pages/_lp.scss */
  .lp-benefits .lp-benefit--half {
    width: 48.7%;
  }
  /* line 99, src/scss/pages/_lp.scss */
  .lp-benefits .lp-benefit--button {
    padding: 50px;
  }
}

@media only screen and (min-width: 1200px) {
  /* line 33, src/scss/pages/_lp.scss */
  .lp-benefits .lp-benefit {
    margin-bottom: 30px;
  }
  /* line 107, src/scss/pages/_lp.scss */
  .lp-benefits .lp-benefit:nth-child(odd) {
    margin-right: 30px;
  }
}

/* line 115, src/scss/pages/_lp.scss */
.lp-how-to-win .section-title {
  margin-bottom: 7.5px;
}

/* line 119, src/scss/pages/_lp.scss */
.lp-how-to-win__grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 15px;
  margin-top: 30px;
}

/* line 126, src/scss/pages/_lp.scss */
.lp-how-to-win__block {
  padding: 30px;
  border-radius: 10px;
  background-color: #272d33;
  color: #768ea8;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 30px;
  position: relative;
}

/* line 136, src/scss/pages/_lp.scss */
.lp-how-to-win__block-step {
  position: absolute;
  top: -17px;
  left: 35px;
  filter: drop-shadow(3px 3px 20px rgba(213, 31, 38, 0.8));
  width: 35px;
  height: 35px;
  border-radius: 100%;
  font-size: 20px;
  font-weight: bold;
  background-color: #d51f26;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: 600px) {
  /* line 155, src/scss/pages/_lp.scss */
  .lp-how-to-win__grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (min-width: 1024px) {
  /* line 161, src/scss/pages/_lp.scss */
  .lp-how-to-win__grid {
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
    margin-top: 60px;
  }
  /* line 167, src/scss/pages/_lp.scss */
  .lp-how-to-win__block {
    padding: 48px 38px;
  }
}

/* line 175, src/scss/pages/_lp.scss */
.lp-join__form {
  padding: 30px;
  border-radius: 10px;
  background-color: #272d33;
}

/* line 181, src/scss/pages/_lp.scss */
.lp-join .button {
  text-align: center;
}

@media only screen and (min-width: 1200px) {
  /* line 187, src/scss/pages/_lp.scss */
  .lp-join__form {
    padding: 110px 70px;
  }
  /* line 191, src/scss/pages/_lp.scss */
  .lp-join .form-checkbox:nth-child(7),
  .lp-join .form-checkbox:nth-child(12) {
    margin-right: 0;
  }
}

/* line 198, src/scss/pages/_lp.scss */
.lp-thankyou {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* line 205, src/scss/pages/_lp.scss */
.lp-thankyou__inner {
  width: 100%;
  max-width: 600px;
  text-align: center;
  padding: 15px;
}

/* line 212, src/scss/pages/_lp.scss */
.lp-thankyou__message {
  background-color: #d51f26;
  filter: drop-shadow(3px 3px 20px rgba(213, 31, 38, 0.8));
  padding: 60px;
  border-radius: 10px;
  margin-bottom: 30px;
}
