/* Footer */
.footer {
  background-color: $color--gray4;
  margin-top: $spacer;
  padding: $spacer 0 $unit-spacing * 3;
  position: relative;

  &__copy {
    width: 100%;
    font-size: 12px;
    line-height: 22px;
    color: $color--gray;
    order: 2;
    text-align: center;

    p + p {
      margin-top: 5px;
    }
  }

  &__logo {
    margin-bottom: 20px;
  }

  &__social {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: $unit-spacing * 2;
  }

  .partner {
    font-size: 12px;
    line-height: 22px;
    text-transform: uppercase;
    margin: $unit-spacing * 2 0 10px;
    color: $color--gray;
  }

  @include min-screen(bp("large")) {
    padding: 106px 0;
    margin-top: 145px;

    &__copy {
      order: -1;
      width: 74.4%;
      text-align: left;
    }

    &__social {
      width: 20%;
      margin-bottom: 0;
    }
  }
}

.scroll-top {
  position: absolute;
  left: 50%;
  top: -25px;
  margin-left: -25px;
  width: 50px;
  height: 50px;
  background-color: $color--primary;
  color: $color--white;
  font-size: 18px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center; 

  i {
    transition: .2s $cubic;
  }

  &:hover i {
    margin-top: -5px;
  }
}

.social {
  display: flex;
  justify-content: center;

  li {
    margin: 0 5px;
  }

  .icon-social {
    width: 34px;
    height: 34px;
    font-size: 16px;
    transition: .2s $cubic;

    &:hover {
      background-color: $color--primary;
      color: $color--white;
    }
  }
}
