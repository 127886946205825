/* Variables */

/* Font */
$font--primary: "Montserrat", sans-serif;

/* Typo */
$font-size--text: 20px;
$line-height--text: 30px;

/* Colors */
$color--black: #000;
$color--white: #fff;
$color--coal: #353535;

$color--gray: #768ea8;
$color--gray2: #36414d;
$color--gray3: #2d3640;
$color--gray4: #272d33;

$color--red: #d51f26;
$color--red2: #ff252d;
$color--green: #2ee66b;

$color--primary: $color--red;

$color--text: $color--white;

/* Misc */
$containter-width: 1200px;
$cubic: cubic-bezier(.4, 0, .2, 1);
$unit-spacing: 15px;
$border-radius: 10px;
$box-shadow: 0 5px 30px rgba($color--black, .3);
$drop-shadow: drop-shadow(3px 3px 20px rgba($color--red, .8));
$spacer: $unit-spacing * 4;

$breakpoints: (
  "minimum": 320px, //*The smallest width that the site is able to shrink to */
  "tiny": 350px,
  "small": 480px,
  "mobile": 600px,
  "phablet": 767px, //*essentially the maximum for iPads in portrait*/
  "tablet": 975px, //*!MAJOR BREAK POINT!*/ /*good place to switch to tablet view*/
  "large": 1024px, //*maximum for iPads in landscape*/
  "page": 1200px, //*!MAJOR BREAK POINT!*//*Point at which the edge of the desktop design meets the edge of the screen*/
  "wide": 1400px,
  "big": 1600px,
  "full": 1900px
);
